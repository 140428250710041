@use "sass:math";

.m-menu-ressorts {
  margin-bottom: $spacer;
  margin-left: - math.div($grid-gutter-width, 4);
  margin-right: - math.div($grid-gutter-width, 4);

  img {
    @extend .img-r;
  }

  &__item {
    margin-bottom: math.div($grid-gutter-width, 2);
    overflow: hidden;
    padding-left: math.div($grid-gutter-width, 4);
    padding-right: math.div($grid-gutter-width, 4);
    position: relative;
  }

  &__link {
    color: $white;

    &:focus,
    &:active,
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &__title {
    bottom: rem(10);
    display: block;
    font-size: rem(16);
    font-weight: 500;
    left: 0;
    line-height: 120%;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 2;
  }

  &__inner {
    position: relative;

    &:before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;

      @include linear-gradient(transparent 0, $black 125%);
      @include opacity(.75);
      @include transition(opacity .2s ease-in);
    }
  }

  &__inner {
    &:hover {
      &:before {
        @include opacity(1);
        @include transition(opacity .2s ease-in);
      }
    }
  }
}
