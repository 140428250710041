@use "sass:math";

.m-navigation-main {
  margin-top: rem(15);

  &__l1u {
    border-bottom: rem(1) solid $light-grey;
    float: left;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
  }

  &__l1l {
    float: left;
    list-style: none;
    margin: 0;
  }

  &__l1aa,
  &__l1a {
    border: rem(1) solid $white;
    border-bottom: rem(1) solid transparent;
    color: $default-font-color;
    float: left;
    font-size: rem(14);
    position: relative;
    padding: rem(2) rem(4);
    z-index: 5;

    &:focus,
    &:active {
      color: $brand-primary;
    }

    &:hover {
      color: $brand-primary;
      text-decoration: none;
    }
  }

  &__l1l:hover .m-navigation-main__l1a,
  &__l1a:hover,
  &__l1aa {
    border: rem(1) solid $light-grey;
    border-bottom: rem(2) solid $white;
    color: $brand-primary;
    margin-bottom: - rem(2);
  }

  &__l1l-bikltv {
    float: right;
    margin-top: rem(3);
    margin-bottom: - rem(2);

    &.m-navigation-main__l1la {
      border: rem(1) solid $light-grey;
      border-bottom: rem(2) solid $white;
      margin-top: - rem(5);

      .e-button-icon-bikltv-small {
        margin: rem(5) rem(5) 0 rem(5);
      }
    }
  }

  @include media-breakpoint-only(lg) {
    &__l1aa,
    &__l1a {
      padding-left: rem(13);
      padding-right: rem(13);
    }
    &.sps--blw {
      background: $white;
      left: 0;
      margin: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 10;

      @include box-shadow(2px 2px 2px 1px rgba(0, 0, 0, 0.05));
      @include opacity(.95);

      .col-md-12 {
        padding-left: 0;
        padding-right: 0;
      }

      .m-navigation-main__l1u {
        padding-left: math.div($grid-gutter-width, 2);
        padding-right: math.div($grid-gutter-width, 2);
      }

      .m-navigation-main__l1a,
      .m-navigation-main__l1aa {
        padding-bottom: rem(5);
        padding-top: rem(5);
      }

      .m-navigation-main__l1l-bikltv {
        margin-top: rem(4);
      }

      .m-navigation-states {
        top: rem(33);
      }
    }
  }
}

/* States navigation (in navigation-main)
------------------------------------------------------------- */
.m-navigation-states {
  background: $white;
  border: rem(1) solid $light-grey;
  left: 0;
  padding: math.div($grid-gutter-width, 2) 0 0 0;
  position: absolute;
  top: rem(27);
  visibility: hidden;
  width: 100%;
  z-index: 4;

  @include box-shadow(2px 2px 2px 1px rgba(0, 0, 0, 0.05));
  @include opacity(0);

  @include transition-property(opacity, visibility);
  @include transition-duration(0.1s, 0s);
  transition-timing-function: ease-out;

  &__l1l {
    list-style: none;
    margin: 0 0 $spacer 0;
    position: relative;
  }

  &__l1a {
    color: $default-font-color;
    display: inline-block;
    font-size: rem(14);
    padding-left: rem(28);
    position: relative;
    z-index: 1;

    @include transition(all .2s ease-in);

    &:hover {
      color: $brand-primary;
      text-decoration: none;

      @include transition(all .2s ease-in);
    }
  }

  &__image {
    left: math.div($grid-gutter-width, 2);
    position: absolute;
    top: rem(7);
    width: rem(20);
    z-index: 0;
  }
}

.m-navigation-main__l1l:hover .m-navigation-states {
  visibility: visible;

  @include opacity(1);

  @include transition-property(visibility, opacity);
  @include transition-duration(0s, 0.3s);
  transition-timing-function: ease-out;
}

/* Footer
------------------------------------------------------------- */
.m-navigation-footer {
  margin-bottom: rem(20);

  &__l1u {
    margin: 0;
    padding: 0;
  }

  &__l1l {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__l1a {
    color: $lightest-grey;
    display: inline-block;
    font-weight: 900;
    position: relative;
    text-decoration: none;

    &:focus,
    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }

  &__l1u li:last-child a {
    margin-right: 0;
    padding-right: 0;

    &:after {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    &__l1l {
      display: inline;
    }
    &__l1a {
      margin-right: rem(15);
      padding-right: rem(15);

      &:after {
        border-right: 1px solid $lightest-grey;
        content: '';
        display: inline-block;
        height: rem(9);
        position: absolute;
        right: 0;
        top: rem(9);
      }
    }
  }
}

/* Meta
------------------------------------------------------------- */
.m-navigation-meta {
  margin-bottom: rem(40);

  &__l1u {
    margin: 0;
    padding: 0;
  }

  &__l1l {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__l1a {
    color: $lightest-grey;
    display: inline-block;
    font-weight: 400;
    position: relative;
    text-decoration: none;

    &:focus,
    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }

  &__l1u li:last-child a {
    margin-right: 0;
    padding-right: 0;

    &:after {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    &__l1l {
      display: inline;
    }
    &__l1a {
      margin-right: rem(15);
      padding-right: rem(15);

      &:after {
        background: $lightest-grey;
        border-right: 1px solid $lightest-grey;
        content: '';
        display: inline-block;
        height: rem(4);
        position: absolute;
        right: 0;
        top: rem(11);
        width: rem(4);

        @include border-radius(50%);
      }
    }
  }
}

/* Breadcrumb
------------------------------------------------------------- */
.m-navigation-breadcrumb {
  margin: - rem(7) 0 0 0;

  dl,
  dt,
  dd {
    margin: 0;
  }

  dt {
    display: none;
  }

  dd {
    float: left;
    font-size: rem(11);
    font-weight: normal;

    &:after {
      content: '›';
      display: inline-block;
      margin: 0 rem(5);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  a {
    color: $default-font-color;
  }
}
