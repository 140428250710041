
body .bootstrap-datetimepicker-widget ul {
  padding: 0;
}

body .bootstrap-datetimepicker-widget td.active:hover,
.bootstrap-datetimepicker-widget td.active:hover:hover,
.bootstrap-datetimepicker-widget td.active:active,
.bootstrap-datetimepicker-widget td.active:hover:active,
.bootstrap-datetimepicker-widget td.active.active,
.bootstrap-datetimepicker-widget td.active:hover.active,
.bootstrap-datetimepicker-widget td.active.disabled,
.bootstrap-datetimepicker-widget td.active:hover.disabled,
.bootstrap-datetimepicker-widget td.active[disabled],
.bootstrap-datetimepicker-widget td.active:hover[disabled] {
  background-image: none;
  background-color: $brand-primary;
}
