@use "sass:math";

.m-gallery-teaser {
  background: #f7f7f7;
  padding-bottom: rem(10);

  img {
    height: auto;
  }

  &__description {
    color: $medium-grey;
    margin-top: rem(10);
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  .e-caption {
    padding-left: math.div($grid-gutter-width, 2);
    padding-right: math.div($grid-gutter-width, 2);
  }
}
