
.m-disclaimer {
  font-size: 90%;

  h4 {
    margin-bottom: rem(5);
  }

  h4,
  p {
    font-size: rem(12);
  }
}
