
/* Base ------------------------------------------------------------- */
.e-ads-basic {
  margin-bottom: rem(25);
  position: relative;

  &:after {
    bottom: - rem(15);
    content: 'Anzeige';
    font-size: rem(10);
    position: absolute;
    right: 0;
    text-transform: uppercase;
  }
}

/* Leaderbord ------------------------------------------------------------- */
.e-ads-leaderboard {
  @extend .e-ads-basic;

  float: right;
  height: rem(90);
  margin-top: $spacer;
  max-width: rem(728);
  position: relative;
  width: 100%;

  &.no-after {
    width: rem(728);
    float: right;
    margin-bottom: rem(-24);
  }
}

/* Skyscraper ------------------------------------------------------------- */
.e-ads-skyscraper {
  @extend .e-ads-basic;

  height: rem(600);
  position: absolute;
  right: - rem( 160 );
  top: 0;
  width: rem(160);
}

/* Content ad 01 ------------------------------------------------------------- */
.e-ads-content-01 {
  @extend .e-ads-basic;

  height: rem(250);
  max-width: rem(300);
  width: 100%;
}

/* Content ad 02 ------------------------------------------------------------- */
.e-ads-content-02 {
  @extend .e-ads-content-01;
}
