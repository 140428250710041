@use "sass:math";

.m-video-latest {
  margin-bottom: $spacer * 2;

  &__full-teaser-item {
    background: $lightest-grey;
  }

  &__inner {
    display: block;
    padding: math.div($grid-gutter-width, 2);

    @include transition(background .2s ease-in);

    * {
      color: $default-font-color;
      margin-bottom: 0;

      @include transition(color .2s ease-in);
    }

    h3 {
      margin-bottom: rem(5);
    }
  }

  a.m-video-latest__inner {
    &:hover {
      background: $light-grey;
      text-decoration: none;

      @include transition(background .2s ease-in);

      * {
        @include transition(color .2s ease-in);
      }
    }
  }

  &__image {
    margin-bottom: rem(10);

    img {
      @extend .img-r;
    }
  }

  &__list-item {
    color: $default-font-color;

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: rem(10);
    }

    p {
      font-size: 80%;
    }

    &:hover {
      text-decoration: none;

      h4 {
        text-decoration: underline;
      }
    }
  }

  &__list-item-small {
    margin-bottom: rem(10);
    padding-left: rem(5);
    padding-right: rem(5);

    .m-video-latest__image {
      margin-bottom: rem(5);
    }

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: rem(5);
    }
  }

  &__socialmedia {
    background: $light-grey;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    padding-top: rem(10);
  }

  &__row-small {
    padding-left: rem(10);
    padding-right: rem(10);

    &__header {
      padding-left: rem(5);
    }
  }

  @include media-breakpoint-up(sm) {
    &__inner {
      padding: $grid-gutter-width;
    }
    &__item-count-2 {
      .m-video-latest__row {
        .col-sm-6:nth-of-type(1) {
          padding-right: math.div($grid-gutter-width, 4);
        }

        .col-sm-6:last-child {
          padding-left: math.div($grid-gutter-width, 4);
        }
      }
    }
    &__item-count-3 {
      .m-video-latest__row {
        .col-sm-4:nth-of-type(1) {
          padding-right: math.div($grid-gutter-width, 6);
        }

        .col-sm-4:nth-of-type(2) {
          padding-left: math.div($grid-gutter-width, 3);
          padding-right: math.div($grid-gutter-width, 3);
        }

        .col-sm-4:nth-of-type(3) {
          padding-left: math.div($grid-gutter-width, 6);
        }
      }
    }
  }
}

/* Video list aside ------------------------------------------------------------- */
.m-video-list-aside {
  img {
    @extend .img-r;
  }

  li {
    list-style: none;
  }

  ul {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
  }

  a {
    color: $default-font-color;
  }

  &__item {
    border-bottom: rem(1) solid $light-grey;
    margin: - rem(3) 0 0 0;
    padding-bottom: math.div($grid-gutter-width, 2);
    padding-top: math.div($grid-gutter-width, 2);

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;

      .m-video-list-aside__hr {
        display: none;
      }
    }

    &:first-child {
      padding-top: 0;
    }
  }

  &__image {
    float: left;
    margin-bottom: 0;
    margin-top: rem(3);
    padding-right: 0;

    img {
      @extend .img-r;
    }
  }

  &__hr {
    hr {
      margin-bottom: rem(15);
      margin-top: rem(15);
    }
  }

  @include media-breakpoint-down(xs) {
    &__image {
      margin-right: rem(10);
      width: rem(120);
    }
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: $spacer;

    .e-header-h3 {
      font-size: rem(14);
      font-weight: normal;
      margin-bottom: 0;
    }
    &__item {
      border-bottom: none;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

/* Video teaser module ------------------------------------------------------------- */
.m-video-teaser {
  &__iframe {
    margin-bottom: rem(10);
  }
}
