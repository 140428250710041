@use "sass:math";

.e-header-h1 {
  font-size: rem(24);
  margin: 0 0 rem(10) 0;

  a {
    color: $default-font-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: rem(34);
  }
}

/* H2
------------------------------------------------------------- */
.e-header-h2 {
  font-size: rem(18);
  margin: 0 0 rem(10) 0;

  a {
    color: $default-font-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: rem(22);
  }
}

/* H3
------------------------------------------------------------- */
.e-header-h3 {
  display: block;
  font-size: rem(15);
  line-height: 125%;
  margin: 0 0 rem(10) 0;

  a {
    color: $default-font-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

a:hover .e-header-h3 {
  color: $default-font-color;
  text-decoration: underline;
}

/* H4
------------------------------------------------------------- */
.e-header-h4 {
  font-size: rem(12);
  line-height: 115%;
  margin: 0 0 rem(5) 0;

  a {
    color: $default-font-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

/* Kicker
------------------------------------------------------------- */
.e-header-kicker {
  color: $medium-grey;
  display: block;
  font-size: rem(12);
  font-weight: normal;
  line-height: 125%;
  margin: 0 0 rem(5) 0;

  a {
    color: $medium-grey;
  }

  &__obtrusive {
    color: $brand-primary;
    font-weight: normal;
    text-transform: uppercase;

    a {
      color: $brand-primary;
    }
  }

  &__views-since {
    display: none;
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: rem(2);
  }
}

/* Kicker large
------------------------------------------------------------- */
.e-header-kicker-large {
  color: $brand-primary;
  font-size: rem(18);
  font-weight: bold;
  line-height: 125%;
  margin: 0;
}

/* Subheader
------------------------------------------------------------- */
.e-subheader {
  font-size: rem(16);
  font-weight: 400;
  margin: 0 0 rem(10) 0;

  @include media-breakpoint-up(lg) {
    font-size: rem(18);
  }
}

/* Header aside
------------------------------------------------------------- */
.e-header-aside {
  font-size: rem(14);
  font-weight: 900;
  padding-bottom: rem(10);
  padding-top: rem(10);
  letter-spacing: rem(1);

  @extend .row !optional;

  a {
    color: $default-font-color;
    text-decoration: none;
  }

  @include media-breakpoint-up(sm) {
    margin-left: 0;
    margin-right: 0;
  }
}

/* Header for image button teaser module
------------------------------------------------------------- */
.e-header-image-button {
  color: $white;
  font-size: rem(24);
  font-weight: 100;
  line-height: 105%;
}

/* Header with icon before
------------------------------------------------------------- */
.e-header-icon-before {
  display: inline-block;
  padding-left: rem(30);
  position: relative;
  width: 100%;

  &:before {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    content: '';
    height: rem(20);
    left: 0;
    position: absolute;
    top: rem(1);
    width: rem(20);
  }
}

/* Icons definitions for .e-header-icon-before
------------------------------------------------------------- */
.e-header-icon-gallery {
  &:before {
    background-image: url("../Images/svg/icon_camera.svg");
  }
}

.e-header-icon-video {
  &:before {
    background-image: url("../Images/svg/icon_play.svg");
  }
}

/* Country zone header
------------------------------------------------------------- */
.e-header-country-zone {
  border-bottom: rem(1) solid $lightest-grey;
  position: relative;
  padding-bottom: rem(20);
  padding-left: rem(85);
  padding-top: rem(10);

  img {
    left: 0;
    position: absolute;
    top: 0;
  }
}

/* Header with a light background as block
------------------------------------------------------------- */
.e-header-block {
  background: $lightest-grey;
  font-size: rem(16);
  font-weight: bold;
  margin: 0;
  padding: math.div($grid-gutter-width, 2);
}

/* Country zone header
------------------------------------------------------------- */
.e-header-flag {
  color: $brand-primary;
  font-size: rem(13);
  font-weight: bold;
  padding: rem(10) 0 rem(10) rem(60);
  position: relative;
  text-transform: uppercase;

  img {
    left: 0;
    margin-top: - rem(15);
    position: absolute;
    top: 50%;
  }
}
