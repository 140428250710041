
.e-iframe-video {
  background: $black;
  padding-bottom: 38%;
  padding-top: rem(60);
  overflow: hidden;
  position: relative;
  width: 100%;

  iframe,
  object,
  embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: 43.8%;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 47%;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: 46.8%;
  }
}

.l-detail {
  .e-iframe-video {
    @include media-breakpoint-up(xl) {
      padding-bottom: 50.1%;
    }
  }
}
