@use "sass:math";

.author-box {
  img {
    border-radius: 100%;
    overflow: hidden;
    float: left;
    margin-right: 15px;
  }

  div {
    width: 100%;
    float: left;
    margin-bottom: 15px;
  }
}

/* Swiper module ------------------------------------------------------------- */
div .m-news-swiper {
  border-bottom: rem(1) solid $lightest-grey;
  margin-bottom: $spacer;

  @extend .row !optional;

  &.swiper-container-horizontal {
    padding-bottom: $spacer * 1.5;
  }

  &__link {
    color: $default-font-color;

    &:hover {
      text-decoration: none;
    }
  }

  &__wrapper {
    margin: 0;
    padding: 0;
  }

  &__text {
    padding-left: math.div($grid-gutter-width, 2);
    padding-right: math.div($grid-gutter-width, 2);
  }

  .default-font-color:hover {
    color: $default-font-color;
    text-decoration: none;
  }

  &__item {
    list-style: none;
  }

  .e-caption {
    margin-top: - rem(5);
  }

  &__vendor,
  &__category,
  &__date {
    color: $medium-grey;
    font-size: rem(12);

    &:after {
      content: ' / ';
      display: inline-block;
    }

    a {
      color: $medium-grey;
    }
  }

  &__meta {
    line-height: 110%;
    margin-top: - rem(8);
    padding-left: 0;
    padding-right: 0;

    span:last-child:after {
      display: none;
    }
  }

  img {
    margin-bottom: rem(10);

    @extend .img-r !optional;
  }

  &__fallback {
    border: 1px solid $brand-primary;
    padding: rem(10);
    box-sizing: border-box;
  }

  &__left {
    background-image: url("../Images/svg/icon_slider-left.svg");
    left: 0;
  }

  &__right {
    background-image: url("../Images/svg/icon_slider-right.svg");
    right: 0;
  }

  &__left,
  &__right {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    top: rem(90);
    height: rem(50);
    width: rem(30);
  }

  @include media-breakpoint-up(xl) {
    &__item {
      img {
        max-width: none;
        width: auto;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    border-bottom: none;
    margin-left: 0;
    margin-right: 0;

    &__left,
    &__right {
      top: rem(180);
    }
    &__text {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    &__left,
    &__right {
      top: rem(145);
    }
  }
  @include media-breakpoint-only(xs) {
    &__item {
      list-style: none;

      .m-news-swiper__meta {
        padding-left: math.div($grid-gutter-width, 2);
        padding-right: math.div($grid-gutter-width, 2);
      }
    }
  }
}

/* Swiper module ------------------------------------------------------------- */
div .m-news-swiper-full {
  &__left,
  &__right {
    bottom: rem(100);
    top: auto;
  }

  @include media-breakpoint-up(sm) {
    &__left,
    &__right {
      bottom: rem(155);
      top: auto;
    }
  }
  @include media-breakpoint-up(lg) {
    &__left,
    &__right {
      bottom: rem(170);
      top: auto;
    }
  }
}

/* List module (default) ------------------------------------------------------------- */
.m-news-list {
  padding-top: math.div($spacer, 2);

  &__item {
    border-bottom: rem(1) solid $lightest-grey;
    margin-bottom: $spacer;
  }

  &__link {
    color: $default-font-color;

    &:hover {
      text-decoration: none;

      h1, h2, h3, h4, h5, h6 {
        text-decoration: underline;
      }
    }
  }

  .default-font-color:hover {
    color: $default-font-color;
    text-decoration: none;
  }

  &__image {
    float: left;
    margin-bottom: 0;
    margin-top: rem(3);

    img {
      @extend .img-r !optional;
    }
  }

  @include media-breakpoint-down(xs) {
    &__image {
      margin-right: rem(10);
      padding-right: 0;
      width: rem(95);
    }
  }
  @include media-breakpoint-up(sm) {
    &__item {
      border-bottom: none;
    }
    &__image {
      padding-right: 0;
    }
  }
}

/* Aside module (sidebar) ------------------------------------------------------------- */
.m-news-aside {
  padding-top: math.div($spacer, 2);

  &__item {
    border-bottom: rem(1) solid $lightest-grey;
    margin-bottom: math.div($spacer, 2);
    padding-bottom: math.div($spacer, 2);

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  &__image {
    float: left;
    margin-bottom: rem(3);
    margin-top: rem(3);
    padding-right: 0;

    img {
      @extend .img-r !optional;
    }
  }

  &__link {
    color: $default-font-color;
  }

  &__more {
    margin-bottom: $spacer;
    margin-top: - rem(5);
  }

  &__more-link {
    color: $medium-grey;
    font-size: rem(12);

    &:hover {
      color: $medium-grey;
    }
  }

  h3,
  h4 {
    margin-bottom: 0;
  }

  @include media-breakpoint-only(xs) {
    &__image {
      margin-right: rem(10);
      width: rem(120);
    }
  }
  @include media-breakpoint-only(md) {
    &__teaser {
      padding-left: rem(10);
    }
  }
  @include media-breakpoint-up(md) {
    margin-bottom: rem(10);
    padding-top: 0;

    &__item {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: rem(10);
    }
    &__image {
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

/* Most read popular news items ------------------------------------------------------------- */
.m-news-popular {
  &__item {
    border-bottom: rem(1) solid $lightest-grey;
    margin-top: rem(10);
    padding-bottom: rem(10);
  }

  .e-header-kicker__ressort {
    display: none;
  }

  @include media-breakpoint-only(xs) {
    &__item {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }
  }
}

/* News detail / single view ------------------------------------------------------------- */
.m-news-detail {
  &__date {
    color: $medium-grey;
    display: block;
    margin-bottom: rem(10);
    margin-top: - rem(5);

    a {
      color: $medium-grey;
    }

    .m-news-detail__date-item {
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  &__date-item {
    &:after {
      content: ' / ';
      display: inline-block;
    }
  }
}
