@use "sass:math";

.m-events-filter {
  font-size: rem(13);

  .form-control {
    font-size: rem(13);
  }

  &__header {
    padding-top: rem(6);
  }

  &__col {
    padding-left: math.div($grid-gutter-width, 4);
    padding-right: math.div($grid-gutter-width, 4);
  }

  &__row {
    margin-left: - rem(4);
    margin-right: - rem(4);
  }
}
