/* We dont want to use font awesome ------------------------------------------------------------- */
.shariff {
  margin-bottom: rem(15);

  .fa {
    background-repeat: no-repeat;
    background-size: rem(20) rem(16);
    display: inline-block;
    margin-left: rem(5);
    height: rem(16);
    width: rem(16);
  }

  .fa-twitter {
    background-image: url("../Images/svg/shariff_twitter.svg");
  }

  .fa-facebook {
    background-image: url("../Images/svg/shariff_facebook.svg");
  }

  .fa-google-plus {
    background-image: url("../Images/svg/shariff_google.svg");
  }

  .fa-info {
    background-image: url("../Images/svg/shariff_info.svg");
    margin-left: rem(2);
  }

  .fa-print {
    background-image: url("../Images/svg/icon_print.svg");
  }

  .print {
    width: rem(31) !important;
  }
}
