
.m-infobox {
  &__box {
    background: $lightest-grey;
    border: rem(1) solid darken($lightest-grey, 5%);
    float: left;
    margin: 0 rem(30) rem(10) 0;
    padding: rem(10);

    @include border-radius(rem(5));

    img {
      float: left;
      margin: rem(4) rem(10) rem(10) 0;
    }

    .bodytext,
    h1,
    h2,
    h3,
    li,
    h4,
    h5,
    h6 {
      font-size: rem(15);
      line-height: 140%;
      margin: 0 0 rem(5) 0;
    }

    ul,
    ol {
      padding-left: rem(15);
    }
  }

  @include media-breakpoint-up(sm) {
    &__box {
      width: rem(230);
    }
  }
}

.m-infobox__box.float-right {
  background: $lightest-grey;
  border: rem(1) solid darken($lightest-grey, 5%);
  float: right;
  margin: 0 rem(30) 0 rem(10);
  padding: rem(10);
}
