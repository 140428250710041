/* Button tag with icon
----------------------------------------------------------------- */

/* Alert button
------------------------------------------------------------- */
.e-button-alert {
  background: $brand-primary;
  border: none;
  color: $white;
  display: inline-block;
  font-size: rem(17);
  font-weight: 900;
  padding: rem(5) rem(10);
  text-transform: uppercase;

  @include border-radius($border-radius);
  @include transition(all .2s ease-in);

  &:hover {
    background: darken($brand-primary, 10%);
    color: $white;
    text-decoration: none;

    @include transition(all .2s ease-in);
  }

  &:focus {
    color: $white;
    text-decoration: none;
  }
}

/* Default button
------------------------------------------------------------- */
.e-button-default {
  @extend .e-button-alert;

  background: $medium-grey;
  text-transform: none;

  @include border-radius(0);

  &:hover {
    background: darken($medium-grey, 10%);
  }
}
