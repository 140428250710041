@use "sass:math";

.e-list-decimal {
  display: inline-block;
  list-style-type: none;
  margin-bottom: $spacer;
  padding-left: 0;
  width: 100%;

  li {
    counter-increment: section;
    padding-left: rem(30);
    position: relative;

    &:before {
      color: $medium-grey;
      content: counter(section) ".";
      font-weight: bold;
      left: rem(5);
      position: absolute;
      top: - rem(2);
    }
  }
}

.e-list-unobtrusive {
  padding-left: 0;
  padding-right: 0;

  li {
    display: inline-block;
    list-style: none;
    margin-left: 0;
    margin-right: rem(10);
  }

  li,
  a {
    color: $medium-grey;
  }
}

.e-list-border {
  margin: - rem(5) 0 $spacer 0;
  padding: 0;

  li {
    border-bottom: rem(1) solid $lightest-grey;
    font-size: rem(12);
    list-style: none;
    margin: 0;
    padding: rem(5) math.div($grid-gutter-width, 2);
  }

  a {
    color: $default-font-color;
  }

  .e-list-border__active {
    color: $brand-primary;
  }

  &__image {
    float: left;
    margin-right: rem(10);
  }
}
