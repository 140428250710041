
.m-contact-box {
  margin-bottom: $spacer;

  &__header {
    background: $light-grey;
    padding-bottom: rem(10);
    padding-top: rem(10);
  }

  address {
    font-size: rem(13);
    line-height: 170%;
  }
}
