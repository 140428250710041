@use "sass:math";

.e-caption {
  color: $medium-grey;
  display: inline-block;
  font-size: rem(12);
  line-height: 120%;
  margin-bottom: rem(10);
  margin-top: rem(5);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    color: $medium-grey;
    text-decoration: none;
  }

  @include media-breakpoint-down(xs) {
    padding-right: math.div($grid-gutter-width, 2);
    box-sizing: border-box;
  }
}

/* Default caption ------------------------------------------------------------- */
.e-caption-full {
  background: $lightest-grey;
  display: block;
  font-size: rem(12);
  line-height: 150%;
  margin-bottom: rem(10);
  margin-top: - rem(10);
  padding: rem(10);

  a {
    color: $medium-grey;
    text-decoration: none;
  }

  i {
    color: $medium-grey;
  }
}
