/* Mixins
----------------------------------------------------------------- */

/* Transition
------------------------------------------------------------- */
@mixin transition($transition ...) {
  transition: $transition;
}

/* Transform origin
------------------------------------------------------------- */
@mixin transform-origin($val) {
  transform-origin: $val;
}

/* Transform
------------------------------------------------------------- */
@mixin transform($val) {
  transform: $val;
}

/* Box shadow
----------------------------------------------------------------- */
@mixin box-shadow($value) {
  box-shadow: $value;
}

/* Linear gradient
----------------------------------------------------------------- */
@mixin linear-gradient($direction, $color-stops...) {
  background: linear-gradient($direction, $color-stops);
}

/* Opacity
------------------------------------------------------------- */
@mixin opacity($opacity) {
  opacity: $opacity;
}

/* transition-property
------------------------------------------------------------- */
@mixin transition-property($val...) {
  transition-property: $val;
}

/* transition-duration
------------------------------------------------------------- */
@mixin transition-duration($val...) {
  transition-duration: $val;
}
