
/* Search module mobile ------------------------------------------------------------- */
.m-search-module-mobile {
  button {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: rem(40);
  }

  input {
    padding-right: rem(50);
  }

  .form-control {
    border: none;
  }
}

/* Search module ------------------------------------------------------------- */
.m-search-module {
  margin-top: rem(21);

  input {
    float: right;
    padding-right: rem(45);
    width: rem(300);

    @include transition(all .3s ease-in);

    &:focus {
      border-color: $medium-grey;

      @include transition(all .3s ease-in);
    }
  }

  button {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: rem(40);

    @include transition(all .3s ease-in);
  }

  &.focused {
    button {
      border-color: $medium-grey;

      @include transition(all .3s ease-in);
    }
  }
}

/* Full search form ------------------------------------------------------------- */
.m-search-form {
}

/* Search results ------------------------------------------------------------- */
.m-search-results {
  &__inner {
    border-top: rem(1) solid $lightest-grey;
    padding-top: $grid-gutter-width * 1.5;
    padding-bottom: $grid-gutter-width;
    position: relative;
  }

  &__badge {
    background: $brand-primary;
    color: $white;
    font-size: rem(12);
    padding: rem(3) rem(7);
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 0;
  }

  &__link {
    color: $default-font-color;
    text-decoration: none;

    &:hover {
      color: $default-font-color;
      text-decoration: none;

      p {
        text-decoration: none;
      }

      h1, h2, h3, h4, h5, h6 {
        text-decoration: underline;
      }
    }
  }

  &__video {
    &__icon {
      background-image: url("../Images/svg/icon_play_youtube.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      float: left;
      margin-top: rem(-1);
      height: rem(28);
      margin-right: rem(10);
      width: rem(33);
    }
  }
}
