
.l-content {
  padding-bottom: rem(15);
  padding-top: rem(15);

  &__bikltv-header {
    margin-bottom: - rem(5);
    padding-bottom: 0;
  }
}
