@use "sass:math";

.rel {
  position: relative;
}

.center {
  text-align: center;
}

body .h {
  display: none;
}

.upper {
  text-transform: uppercase;
}

div .bg-lightest-grey,
.bg-lightest-grey {
  background: $lightest-grey;
}

div .bg-dark-grey,
.bg-dark-grey {
  background: $dark-grey;
}

.spacer-bottom {
  margin-bottom: $spacer;
}

.spacer-bottom-double {
  margin-bottom: $spacer*2;
}

.spacer-top {
  margin-top: $spacer;
}

.spacer-top-half {
  margin-top: math.div($spacer, 2);
}

.spacer-top-double {
  margin-top: $spacer*2;
}

.spacer-half-bottom {
  margin-bottom: math.div($spacer, 2);
}

.img-r {
  height: auto;
  max-width: 100%;
  width: 100%;
}

.clear {
  clear: both;
  float: none;
}

.no-gutters {
  padding-left: 0;
  padding-right: 0;
}

.f {
  clear: both;
  float: left;
  width: 100%;
}

.text-right {
  text-align: right;
}

.bargraph {
  margin-top: rem(5);
}

.error {
  color: $state-danger-border;
  font-size: rem(12);
  margin-top: rem(2);
}

.f3-form-error {
  border-color: $state-danger-text;
  color: $state-danger-text;
}

.no-js {
  .visible-js {
    display: none;
  }

  .hidden-js {
    display: inline-block;
  }
}

.js {
  .visible-js {
    display: inline-block;
  }

  .hidden-js {
    display: none;
  }
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.brand-primary-color {
  color: $brand-primary;
}

.default-font-color {
  color: $default-font-color;
}

.pointer {
  cursor: pointer;
}

body .no-after {
  &:after {
    display: none !important;
  }
}

/* Form helpers/widgets ------------------------------------------------------------- */
// label with checkbox
.fh-label-checkbox {
  padding-left: rem(15);
  position: relative;
  line-height: 120%;

  input {
    left: 0;
    position: absolute;
    top: rem(1);
  }
}

/* Form helpers/widgets ------------------------------------------------------------- */
select.form-control {
  &:not(textarea),
  &:not(select[size]),
  &:not(select[multiple]) {
    height: $input-height;
  }
}
