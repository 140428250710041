
.container-fluid {
  @include media-breakpoint-up(xl) {
    background: $white;
    max-width: rem(1000);
  }
}

body .container-fluid-no-bg {
  @include media-breakpoint-up(xl) {
    background: transparent;
  }
}

.bodytext {
  font-size: rem(14);
  line-height: 140%;

  @include media-breakpoint-up(sm) {
    margin-bottom: rem(15);
  }
  @include media-breakpoint-up(lg) {
    font-size: rem(15);
  }
}

.bodytext-aside .bodytext,
.bodytext-small {
  font-size: 75%;
}
