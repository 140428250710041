@use "sass:math";

.m-image-border-teaser {
  border: rem(1) solid $lightest-grey;
  margin-bottom: $spacer;

  &__image {
    padding: rem(15);

    img {
      @extend .img-r;
    }
  }

  &__description {
    padding: math.div($grid-gutter-width, 2);
  }

  a {
    color: $default-font-color;

    &:hover {
      text-decoration: none;
    }
  }

  @include media-breakpoint-down(sm) {
    .e-header-aside {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
