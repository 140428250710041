
.swiper-container {
  .prev,
  .next {
    position: absolute;
    z-index: 1;

    @include opacity(.7);
    @include transition(all .2s ease-in);

    &:hover {
      @include opacity(1);
      @include transition(all .2s ease-in);
    }
  }

  .swiper-button-disabled {
    @include opacity(0);
  }

  .swiper-pagination-bullet {
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    background: $brand-primary;
  }
}
