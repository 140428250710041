@use "sass:math";

.m-image-button-teaser {
  margin-bottom: $spacer;

  img {
    @extend .img-r;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  &__description {
    padding-bottom: rem(15);
    padding-left: math.div($grid-gutter-width, 2);
    padding-right: math.div($grid-gutter-width, 2);
    padding-top: rem(10);

    p {
      line-height: 125%;
      margin-bottom: 0;
    }
  }

  &__image {
    position: relative;

    &:after {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;

      @include linear-gradient(transparent 0, $black 125%);
    }
  }

  a {
    color: $default-font-color;
  }

  &__header-no-image {
    margin-top: math.div($grid-gutter-width, 2);
  }

  a &__description {
    color: $default-font-color;
    padding-right: rem(60);
    position: relative;
    text-decoration: none;

    &:after {
      background-image: url("../Images/svg/icon_angleright.svg");
      background-repeat: no-repeat;
      background-position: right center;
      content: '';
      display: block;
      height: rem(40);
      margin-top: - rem(20);
      position: absolute;
      right: 0;
      top: 50%;
      width: rem(40);

      @include opacity(.5);
    }
  }

  &__header {
    bottom: math.div($grid-gutter-width, 2);
    left: math.div($grid-gutter-width, 2);
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 2;
  }

  @include media-breakpoint-up(sm) {
    margin-left: 0;
    margin-right: 0;
  }
}
