/* Mobile header ------------------------------------------------------------- */
.l-header-mobile {
  background: $white;
  height: rem($l-header-height-xs);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &__logo {
    height: rem(50);
    margin-right: rem(10);
    margin-top: rem(10);
    max-width: 100%;
    position: absolute;
    right: 0;
    width: rem(122);
  }

  @include box-shadow($l-header-shadow);

  .e-hamburger {
    left: rem(5);
    top: rem(8);
    position: absolute;
  }
}

/* Desktop header ------------------------------------------------------------- */
.l-header {
  &__logo {
    height: rem(70);
    margin-top: rem(15);
    width: rem(185);
  }

  &__container {
    padding-bottom: rem(15);
    padding-top: rem(15);
  }
}
