@use "sass:math";

.e-hamburger {
  background: none;
  border: none;
  display: block;
  font-size: 0;
  height: $e-hamburger-height;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-indent: -9999px;
  width: $e-hamburger-width;
  appearance: none;
  @include border-radius(none);
  @include transition(background .3s);

  &:focus {
    outline: none;
  }

  span {
    background: $brand-primary;
    display: block;
    height: $e-hamburger-thickness;
    left: $e-hamburger-pad;
    position: absolute;
    right: $e-hamburger-pad;
    top: math.div($e-hamburger-height, 2) - math.div($e-hamburger-thickness, 2);

    &:before,
    &:after {
      background: $brand-primary;
      content: "";
      display: block;
      height: $e-hamburger-thickness;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &:before {
      top: -$e-hamburger-thickness - $e-hamburger-space;
    }

    &:after {
      bottom: -$e-hamburger-thickness - $e-hamburger-space;
    }
  }

  // type of animation
  @extend .e-hamburger__to-arrow;
}


/* Hamburger icon to arrow animation styles ------------------------------------------------------------- */
.e-hamburger__to-arrow {
  span {
    @include transition(transform $e-hamburger-duration);

    &:before {
      @include transform-origin(top right);
      @include transition(transform $e-hamburger-duration, width $e-hamburger-duration, top $e-hamburger-duration);
    }

    &:after {
      @include transform-origin(bottom right);
      @include transition(transform $e-hamburger-duration, width $e-hamburger-duration, bottom $e-hamburger-duration);
    }
  }

  &.is-active {
    span {
      @include transform(rotate(180deg));

      &:before,
      &:after {
        width: 50%;
      }

      &:before {
        top: 0;

        @include transform(translateX(math.div($e-hamburger-width, 2) - $e-hamburger-pad + $e-hamburger-thickness) translateY(math.div($e-hamburger-thickness, 2)) rotate(45deg));
      }

      &:after {
        bottom: 0;

        @include transform(translateX(math.div($e-hamburger-width, 2) - $e-hamburger-pad + $e-hamburger-thickness) translateY(- math.div($e-hamburger-thickness, 2)) rotate(-45deg));
      }
    }
  }
}

