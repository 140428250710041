/* Button tag with icon as background
----------------------------------------------------------------- */

/* Default
------------------------------------------------------------- */
.e-button-icon {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  border: none;
  display: inline-block;
  line-height: 2000px;
  overflow: hidden;
}

/* Search icon
------------------------------------------------------------- */
.e-button-icon-search {
  background-image: url("../Images/svg/icon_search.svg");
  background-size: 45% 45%;
  border-left: 1px solid $light-grey;
  height: rem(20);
  width: rem(20);

  @include transition(all .3s ease-in);
}

/* Scroll top icon
------------------------------------------------------------- */
.e-button-scroll-top {
  background: $white;
  background-image: url("../Images/svg/icon_angleright.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  border-top: rem(1) solid $white;
  bottom: 0;
  height: rem(40);
  line-height: rem(1000);
  overflow: hidden;
  position: fixed;
  right: 0;
  width: rem(40);
  z-index: 20;

  @include box-shadow(0 -1px 2px 1px rgba(0, 0, 0, 0.1));
  @include opacity(0);
  @include transform(rotate(-90deg));
  @include transition(all .2s ease-in);


  @include media-breakpoint-up(sm) {
    height: rem(50);
    width: rem(50);
  }
}

.scrolled .e-button-scroll-top {
  @include opacity(.8);
}

/* Bildungsklick TV Logo
------------------------------------------------------------- */
.e-button-icon-bikltv {
  background-image: url("../Images/svg/logo_bildungsklick_tv.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  display: inline-block;
  line-height: rem(500);
  overflow: hidden;
  height: rem(30);
  width: rem(115);
}

.e-button-icon-bikltv-small {
  @extend .e-button-icon-bikltv;

  height: rem(20);
  width: rem(120);
}

/* Newsletter button
------------------------------------------------------------- */
.e-button-icon-newsletter {
  background: $brand-primary;
  color: $white;
  display: inline-block;
  font-size: rem(11);
  font-weight: 400;
  overflow: hidden;
  padding: rem(3) rem(5);
  text-decoration: none;
  text-transform: uppercase;

  @include transition(all .2s ease-in);

  &:hover {
    background: lighten($brand-primary, 10%);
    color: $white;
    text-decoration: none;

    @include transition(all .2s ease-in);
  }
}

/* Twitter button
------------------------------------------------------------- */
.e-button-icon-twitter {
  background-image: url("../Images/svg/icon_twitter.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  display: inline-block;
  height: rem(22);
  line-height: 500px;
  overflow: hidden;
  width: rem(22);

  @include opacity(1);
  @include transition(all .2s ease-in);

  &:hover {
    @include opacity(.8);
    @include transition(all .2s ease-in);
  }
}

/* linked in button */
.e-button-icon-linkedin {
  @extend .e-button-icon-twitter;

  background-image: url("../Images/svg/icon_linkedin.svg");
}

/* Bluesky button */
.e-button-icon-bluesky {
  @extend .e-button-icon-twitter;

  background-image: url("../Images/svg/icon_bluesky.svg");
}

/* Twitter button
------------------------------------------------------------- */
.e-button-icon-youtube {
  @extend .e-button-icon-twitter;

  background-image: url("../Images/svg/icon_youtube.svg");
}

/* Twitter button
------------------------------------------------------------- */
.e-button-icon-rss {
  @extend .e-button-icon-twitter;

  background-image: url("../Images/svg/icon_rss.svg");
}

/* Reply button
------------------------------------------------------------- */
.e-button-icon-reply {
  color: $medium-grey;
  font-size: rem(12);
  padding-left: rem(30);
  position: relative;

  @include transition(all .2s ease-in);

  &:before {
    background-image: url("../Images/svg/icon_reply.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    content: '';
    height: rem(15);
    left: 0;
    position: absolute;
    top: rem(1);
    width: rem(15);
  }

  &:hover {
    color: darken($medium-grey, 15%);
    text-decoration: none;

    @include transition(all .2s ease-in);
  }
}

/* Camera button (i.e. galleries)
------------------------------------------------------------- */
.e-button-icon-camera {
  color: $default-font-color;
  display: inline-block;
  font-size: rem(15);
  padding-left: rem(30);
  padding-top: rem(2);
  position: relative;

  @include transition(all .2s ease-in);

  &:before {
    background-image: url("../Images/svg/icon_camera.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    content: '';
    height: rem(20);
    left: 0;
    position: absolute;
    top: rem(1);
    width: rem(20);
  }

  &:hover {
    color: $default-font-color;
    text-decoration: none;

    @include transition(all .2s ease-in);
  }
}

.e-button-icon-camera-bg-lightest {
  @extend .e-button-icon-camera;

  background: $lightest-grey;
  padding: rem(10) rem(10) rem(10) rem(40);

  &:before {
    left: rem(10);
    top: rem(11);
  }

  &:hover {
    background: darken($lightest-grey, 10%);
  }
}


/* Icon arrow left
------------------------------------------------------------- */
.e-button-icon-arrow-left {
  color: $brand-primary;
  display: inline-block;
  padding-left: rem(20);
  padding-top: rem(2);
  position: relative;

  @include transition(all .2s ease-in);

  &:before {
    background-image: url("../Images/svg/icon_arrow_left.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    content: '';
    height: rem(20);
    left: 0;
    position: absolute;
    top: rem(5);
    width: rem(20);
  }

  &:hover {
    text-decoration: none;

    @include transition(all .2s ease-in);
  }
}


/* Icon arrow right
------------------------------------------------------------- */
.e-button-icon-arrow-right {
  color: $brand-primary;
  display: inline-block;
  padding-right: rem(20);
  padding-top: rem(2);
  position: relative;

  @include transition(all .2s ease-in);

  &:before {
    background-image: url("../Images/svg/icon_arrow_right.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    content: '';
    height: rem(20);
    right: 0;
    position: absolute;
    top: rem(5);
    width: rem(20);
  }

  &:hover {
    text-decoration: none;

    @include transition(all .2s ease-in);
  }
}


/* Datepicker button
------------------------------------------------------------- */
.e-button-datepicker {
  background-image: url("../Images/svg/icon_calendar.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  cursor: pointer;
  display: inline-block;
  height: rem(20);
  width: rem(20);
}

.e-button-datepicker-small {
  height: rem(17);
  width: rem(17);
}

/* Google login button
------------------------------------------------------------- */
.e-button-login-google {
  background-image: url("../Images/svg/login_google.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  cursor: pointer;
  display: inline-block;
  height: rem(35);
  line-height: rem(200);
  margin-left: rem(10);
  overflow: hidden;
  width: rem(200);
}

/* Google login facebook
------------------------------------------------------------- */
.e-button-login-facebook {
  background-image: url("../Images/svg/login_facebook.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  cursor: pointer;
  display: inline-block;
  height: rem(35);
  line-height: rem(200);
  margin-left: rem(10);
  overflow: hidden;
  width: rem(200);
}
