@use "sass:math";

.m-comments {
  padding: 0;

  ul {
    margin: 0;
    padding: 0;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__header {
    margin-bottom: rem(15);

    b {
      font-size: rem(15);
    }

    i {
      color: $medium-grey;
      font-size: rem(12);
      font-style: normal;
    }
  }

  &__item {
    border-top: 1px solid $lightest-grey;
    padding-bottom: $grid-gutter-width;
    padding-top: $grid-gutter-width;
    position: relative;
  }

  &__bodytext {
    font-size: rem(15);
    margin-bottom: rem(15);
  }

  li > .m-comments__item {
    padding-left: math.div($grid-gutter-width, 2);
    padding-right: math.div($grid-gutter-width, 2);
  }

  li > ul > li > .m-comments__item {
    padding-left: rem(45);
  }

  &__form {
    padding-top: rem(15);
    width: 100%;

    .form-control {
      margin-bottom: rem(15);
    }
  }

  &__form-new {
    padding-bottom: rem(15);
    padding-top: 0;
  }

  @include media-breakpoint-up(md) {
    ul > ul > li > .m-comments__item {
      padding-left: rem(75);
    }
    ul > ul > ul > li .m-comments__item {
      padding-left: rem(105);
    }
  }
}
