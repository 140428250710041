
.l-footer {
  color: $lightest-grey;
  padding-bottom: rem(40);
  padding-top: rem(40);

  &__logo {
    background-color: $white;
    border: 1px solid $white;
    height: rem(46);
    margin-bottom: rem(40);
    width: rem(120);
  }

  small {
    font-size: rem(12);
  }
}
