
.m-menubar-social {
  span {
    font-size: rem(11);
    padding: rem(3) rem(3) rem(3) 0;
  }

  span,
  a {
    display: inline-block;
    overflow: hidden;
  }

  &__button {
    float: right;
    margin-left: rem(5);
  }
}
