/* Variables
----------------------------------------------------------------- */


/* Colors
------------------------------------------------------------- */
$brand-primary: #c51428;
$white: #fff;
$black: #000;
$page-bgcolor: #d5d4d4;
$lightest-grey: #f1f1f1;
$light-grey: #ddd;
$medium-grey: #969696;
$dark-grey: #3b3b3b;
$darkest-grey: #1a1a1a;

/* Fonts
------------------------------------------------------------- */
$default-font-color: $darkest-grey;

/* Box shadows
------------------------------------------------------------- */
$l-header-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.05);

/* Dimensions
------------------------------------------------------------- */
$l-header-height-xs: 66;

/* Elements
------------------------------------------------------------- */
// Hamburger icon
$e-hamburger-width: 46px;
$e-hamburger-height: 50px;
$e-hamburger-thickness: 4px;
$e-hamburger-pad: 8px;
$e-hamburger-space: 5px;
$e-hamburger-duration: .3s;

/* Modules
------------------------------------------------------------- */

/* Twitter bootstrap overrides
------------------------------------------------------------- */
$input-border-focus: $brand-primary;
$alert-border-radius: 0;
$state-danger-border: $brand-primary;
$state-danger-text: $brand-primary;
/* Plugins
------------------------------------------------------------- */
// jQuery mmenu
$mm_backgroundColor: $brand-primary;
$mm_textColor: $white;
$mm_dimmedTextColor: $white;
