@use "sass:math";

/* Checkbox groups ------------------------------------------------------------- */
.e-formwidgets-checkboxes {
  label {
    font-size: rem(14);
    line-height: 115%;
    padding-left: $grid-gutter-width * 1.15;
    position: relative;

    input {
      left: math.div($grid-gutter-width, 2);
      position: absolute;
      top: rem(2);
    }
  }
}
