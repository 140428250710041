.alert {
  h4 {
    font-size: rem(18);
  }

  .alert-message {
    @extend .bodytext;

    margin: 0;
  }
}

.form-control[readonly]:focus {
  border-color: #ccc;
}
