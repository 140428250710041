
html,
body {
  background: $white;
  color: $default-font-color;
  font-family: "Lato", sans-serif;
  min-height: 100%;
}

html {
  overflow-y: scroll;
}

a,
label,
button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: 900;
}

img {
  max-width: 100%;
}

iframe {
  border: none;
  width: 100%;
}

a:focus,
a:active {
  color: $default-font-color;
  text-decoration: none;
}

a:hover {
  color: $default-font-color;
}
