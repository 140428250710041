.ce-align-left {
  text-align: left;
}

.ce-align-center {
  text-align: center;
}

.ce-align-right {
  text-align: right;
}

.ce-table td, .ce-table th {
  vertical-align: top;
}

.ce-textpic, .ce-nowrap .ce-bodytext, .ce-gallery, .ce-row, .ce-uploads li, .ce-uploads div {
  overflow: hidden;
}

.ce-left .ce-gallery, .ce-column {
  float: left;
}

.ce-center .ce-outer {
  position: relative;
  float: right;
  right: 50%;
}

.ce-center .ce-inner {
  position: relative;
  float: right;
  right: -50%;
}

.ce-right .ce-gallery {
  float: right;
}

.ce-gallery figure {
  display: table;
  margin: 0;
}

.ce-gallery figcaption {
  display: table-caption;
  caption-side: bottom;
}

.ce-gallery img {
  display: block;
}

.ce-gallery iframe {
  border-width: 0;
}

.ce-border img,
.ce-border iframe {
  border: rem(2) solid black;
  padding: 0;
}

.ce-intext.ce-right .ce-gallery, .ce-intext.ce-left .ce-gallery, .ce-above .ce-gallery {
  margin-bottom: rem(10);
}

.ce-intext.ce-right .ce-gallery {
  margin-left: rem(10);
}

.ce-intext.ce-left .ce-gallery {
  margin-right: rem(10);
}

.ce-below .ce-gallery {
  margin-top: rem(10);
}

.ce-column {
  margin-right: rem(10);
}

.ce-column:last-child {
  margin-right: 0;
}

.ce-row {
  margin-bottom: rem(10);
}

.ce-row:last-child {
  margin-bottom: 0;
}

.ce-above .ce-bodytext {
  clear: both;
}

.ce-intext.ce-left ol, .ce-intext.ce-left ul {
  padding-left: rem(40);
  overflow: auto;
}

.ce-uploads {
  margin: 0;
  padding: 0;
}

.ce-uploads li {
  list-style: none outside none;
  margin: 1em 0;
}

.ce-uploads img {
  float: left;
  padding-right: 1em;
  vertical-align: top;
}

.ce-uploads span {
  display: block;
}

/* Custom styles ------------------------------------------------------------- */
.ce-textpic {
  margin-bottom: $spacer;

  @include media-breakpoint-down(sm) {
    .ce-bodytext,
    .ce-gallery {
      clear: both;
      display: block;
      float: none;
      margin: 0;
    }
    img {
      height: auto;
      width: 100%;
      max-width: 100%;
    }
  }
}
