@use "sass:math";

.m-newsletter-subscription {
  padding-top: rem(20);
  padding-bottom: rem(20);

  legend {
    font-size: rem(20);
    font-weight: 900;
  }

  h4 {
    font-size: rem(15);
    margin: 0 0 rem(10) 0;
  }

  .fh-label-checkbox {
    float: left;
    font-size: rem(12);
    margin-right: rem(15);

    input {
      height: rem(12);
      margin: 0;
      width: rem(12);
    }
  }
}

/* Newsletter subscription box ------------------------------------------------------------- */
.m-newsletter-subscription-box {
  margin-bottom: $spacer;

  fieldset {
    background: $lightest-grey;
    padding-bottom: math.div($grid-gutter-width, 2);

    legend {
      background: $brand-primary;
      color: $white;
      font-size: rem(15);
      margin-bottom: math.div($grid-gutter-width, 2);
      text-transform: uppercase;
      padding: rem(10) math.div($grid-gutter-width, 2);
    }
  }

  .form-group {
    margin-bottom: rem(10);
  }

  label,
  .form-control {
    font-size: rem(12);
  }

  label {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    .form-gender {
      padding-right: 0;
    }
  }
}
