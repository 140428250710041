
.l-page {
  padding-top: rem($l-header-height-xs);

  @include media-breakpoint-up(lg) {
    padding-top: 0;
  }
  @include media-breakpoint-up(xl) {
    background: $page-bgcolor;

    @include linear-gradient(#f3f3f3 0, $page-bgcolor 500px);
  }
}
